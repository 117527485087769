import React from 'react';
import styled from 'styled-components';

const Spinner = ({message}) => (
    <div style={{ height: '100vh', paddingTop: '36vh'
     }}>
        <div style={{ marginBottom: '36px'}}>
            <StyledSpinner viewBox="0 0 50 50">
                <circle
                className="path"
                cx="25"
                cy="25"
                r="20"
                fill="none"
                strokeWidth="4"
                />
            </StyledSpinner>
        </div>
        <p style={{ fontFamily: 'sans-serif', fontSize: '16px', color: '#111824'}}>{message}</p>
    </div>
);

const StyledSpinner = styled.svg`
  animation: rotate 2s linear infinite;
  width: 36px;
  height: 36px;
  
  & .path {
    stroke: #256BF6;
    stroke-linecap: round;
    animation: dash 1.5s ease-in-out infinite;
  }
  
  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }
  @keyframes dash {
    0% {
      stroke-dasharray: 1, 150;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -35;
    }
    100% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -124;
    }
  }
`;

export default Spinner;