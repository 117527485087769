import React from "react";
import "./App.css";
import { Viewer } from "./components/Viewer";

function App() {
    const url = new URL(window.location.href);
    const document = url.searchParams.get("document") || "";
    const disableElements = url.searchParams.get("disableElements")?.split(",");
    const disableAnnotations = url.searchParams.get("disableAnnotations") || 'true';
    const disableDownload = url.searchParams.get("disableDownload") || 'true';
    const gqlUri = url.searchParams.get("gql_uri");
    const pageNumber = url.hash ? parseInt(url.hash.replace(/\D/g, ''), 10) :1;

    function extractDocument(url: string): string {
        const match = url.match(/document=([^&]+(?:&[^=&]+=[^&]+)*)/);
        if (match && match[1]) {
            return match[1];
        }
        return '';
    }

    if(document.length === 0) {
        return <div/>;
    }

    return (
        <div className="App">
            <Viewer
                document={extractDocument(window.location.href)}
                disableElements={disableElements}
                disableAnnotations={disableAnnotations}
                disableDownload={disableDownload}
                gqlUri={gqlUri}
                pageNumber={pageNumber}
            />
        </div>
    );
}

export default App;
