import React, { FunctionComponent } from "react";
import { PdfViewer } from "./components/PdfViewer";
import { ViewerProps } from "./types";

export const Viewer: FunctionComponent<ViewerProps> = ({
    document,
    disableElements,
    disableAnnotations,
    disableDownload,
    gqlUri,
    pageNumber,
}) => {
    return (
        <div>
            {/* <button onClick={changeDocument}>Change Document</button> */}
            <PdfViewer
                document={document}
                disableElements={disableElements}
                disableAnnotations={disableAnnotations}
                disableDownload={disableDownload}
                gqlUri={gqlUri}
                pageNumber={pageNumber}
            />
        </div>
    );
};
