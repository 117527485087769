import React, { FunctionComponent, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import WebViewer from "@pdftron/webviewer";
import { ViewerProps } from "../types";
import { pdfTronLicenseKey } from "./licenseKey.json";
import { isStitchLink, getRedirectedUrl } from "./stitchUtils";
import Spinner from "./Spinner";
import BotCryingAvatar from './bot-crying-avatar.svg'

const ViewerDiv = styled.div`
    height: 100vh;
`;

//check if url is encoded
function isEncoded(component: string) {
    component = component || '';
    return component !== decodeURIComponent(component);
}

//decode component if necessary
function decodedComponent(component: string) {
    if (isEncoded(component)) {
        return decodeURIComponent(component);
    }
    else {
        return component;
    }
}

const getUrl = async (url: string, gqlUri: string | null) => {
    const redirectedURL = await getRedirectedUrl(url, gqlUri);
    return redirectedURL;
}

const ErrorMessageComponent = ({ message, paramErrorMessage }: { message: string, paramErrorMessage?: string }) => {
    return (
        <div style={{ height: '100vh', paddingTop: '36vh' }}>
            <img style={{ width: '12vh', height: '12vh' }} src={BotCryingAvatar} alt='BotCryingAvatar' />
            <p style={{ fontFamily: 'sans-serif', fontSize: '18px', color: '#111824', fontWeight: '600', marginTop: 18, marginBottom: 8 }}>{message}</p>
            {paramErrorMessage && <p style={{ fontFamily: 'sans-serif', fontSize: '16px', color: '#111824', margin: 0, padding: 0 }}>{paramErrorMessage}</p>}
        </div>
    )
}

export const PdfViewer: FunctionComponent<ViewerProps> = ({
    document: documentURL,
    disableElements = [""],
    disableAnnotations = true,
    disableDownload = true,
    gqlUri = "",
    pageNumber,
}) => {
    const [docUrl, setDocUrl] = useState(documentURL);
    const [openExternal, setOpenExternal] = useState(false);
    const [isExternalCheck, setIsExternalCheck] = useState(false);
    const [noTokenError, setNoTokenError] = useState(false);
    const [waitForMessageWithToken, setWaitForMessageWithToken] = useState(false)
    const [errorWithParams, setErrorWithParams] = useState(false)
    const [paramErrorMessage, setParamErrorMessage] = useState('')

    const canOpenPDF = async (_document: string, gqlUri: string) => {
        let finalURL = _document;
        // checks for is url a s3 url
        // need [s3. or s3-] and amazonaws.com
        // covers all s3 regions [ref: https://docs.aws.amazon.com/general/latest/gr/s3.html]
        const isS3Regex = /^https:\/\/(?=.*s3[.-].*)(?=.*amazonaws\.com.*).*/;
        if (!isS3Regex.test(finalURL)) {
            finalURL = decodedComponent(_document);
        }
        // function isStitchLink expects a decoded url, 
        if (isStitchLink(finalURL) && gqlUri) {
            finalURL = await getUrl(finalURL, gqlUri);
            if (finalURL === 'Invalid Session or URL') {
                setErrorWithParams(true)
                setParamErrorMessage(finalURL)
                return;
            }
        } else {
            finalURL = finalURL.split('?x-session')[0]
        }

        try {
            const response = await fetch(finalURL, {
                method: 'GET',
                headers: {
                    'Range': 'bytes=0-0'
                }
            })

            if (response.status <= 208 && response.status >= 200) {
                setOpenExternal(false)
            } else {
                setOpenExternal(true)
            }
            setIsExternalCheck(true)
        } catch (error) {
            setOpenExternal(true)
            setIsExternalCheck(true)
        }
    }

    useEffect(() => {
        // fetch session token from session storage
        const secureToken = window.sessionStorage.getItem('secureToken')
        if (secureToken) {
            setDocUrl(`${documentURL}?x-session=${secureToken}`)
        }
        //fetch from cookies if not then fetch from post message
        window.addEventListener('message', (event) => {
            const allowedOrigins = /^(https?:\/\/(?:.*\.)?botmd\.(?:sg|io))$/;
            if (!allowedOrigins.test(event.origin)) return;
            if (event instanceof MessageEvent) {
                if (event.data.type === 'secureToken') {
                    setDocUrl(`${documentURL}?x-session=${event.data.secureToken}`)
                    // Store or update session token received from app or einstein
                    window.sessionStorage.setItem('secureToken', event.data.secureToken)
                }
            }
        })
    }, [documentURL])

    useEffect(() => {
        if (docUrl.includes('x-session')) {
            canOpenPDF(docUrl, gqlUri || '')
            setNoTokenError(false)
        } else {
            setNoTokenError(true)
        }
    }, [docUrl, gqlUri])

    // there is a delay in getting session token from event listener
    // to differentiate b/w delay and no token, wait for 10 sec to show error
    useEffect(() => {
        setTimeout(() => { setWaitForMessageWithToken(true) }, 10000)
    }, [])

    const viewerDiv = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (openExternal) {
            const limit = 5;
            let current = 0;
            const decodedDocumentURL = decodedComponent(documentURL)
            const link = "https://drive.google.com/viewerng/viewer?embedded=true&url=" + decodedDocumentURL?.split('?x-session')[0].replace('https://', '');
            window.location.href = link
            const timeoutRef = setInterval(() => {
                if (window.location.href !== link && current <= limit) {
                    current = current + 1
                    window.location.href = link
                } else {
                    clearInterval(timeoutRef)
                }
            }, 3000)
        }
    }, [openExternal, documentURL])

    useEffect(() => {
        const asyncWrapper = async () => {
            const decodedURL = decodedComponent(docUrl);
            if (isStitchLink(decodedURL) && !docUrl.includes('x-session')) {
                return
            }
            WebViewer(
                {
                    path: "/webviewer",
                    disabledElements: ['downloadButton', 'printButton', 'menuButton'],
                    licenseKey: pdfTronLicenseKey

                },
                viewerDiv.current as HTMLDivElement
            )
                .then(async (instance) => {
                    const { Core, UI } = instance;
                    const { documentViewer } = Core;
                    const { Download, Annotations } = UI.Feature;
                    const { FitWidth } = UI.FitMode;
                    var disableFeatures = [];
                    if (disableAnnotations === "true") {
                        disableFeatures.push(Annotations);
                    }
                    if (disableDownload === "true") {
                        disableFeatures.push(Download);
                    }

                    //support for encoded uri components, decode it if necessary
                    const decodedURL = decodedComponent(docUrl);
                    //only get the redirectedURL if it is a stitch link
                    if (isStitchLink(decodedURL)) {
                        if (docUrl.includes('x-session')) {
                            const redirectedURL = await getUrl(decodedURL, gqlUri);
                            UI.loadDocument(redirectedURL)
                        }
                    }
                    else {
                        UI.loadDocument(docUrl.split('?x-session')[0]);
                    }

                    UI.disableFeatures(disableFeatures);
                    UI.disableElements(disableElements);
                    UI.setFitMode(FitWidth);
                    UI.setToolMode("Pan");

                    // const downloadPdf = async () => {
                    //     var a = document.createElement("a");
                    //     document.body.appendChild(a);
                    //     a.style.display = "none";
                    //     const doc = documentViewer.getDocument();
                    //     const data = await doc.getFileData({
                    //         downloadType: "pdf",
                    //     });
                    //     const arr = new Uint8Array(data);
                    //     const blob = new Blob([arr], {
                    //         type: "application/pdf",
                    //     });
                    //     const url = window.URL.createObjectURL(blob);
                    //     a.href = url;
                    //     a.download = doc.getFilename();
                    //     a.click();
                    //     window.URL.revokeObjectURL(url);
                    // };

                    // UI.setHeaderItems((header) => {
                    //     header.push({
                    //         type: "customElement",
                    //         title: "Download",
                    //         render: () => (
                    //             <button
                    //                 style={{
                    //                     backgroundColor: "#36a6f0",
                    //                     border: "none",
                    //                     fontFamily: "inherit",
                    //                     fontSize : "1vh",
                    //                     color: "#ffffff",
                    //                     fontWeight: "bold",
                    //                     padding: "7px 10px",    
                    //                     marginRight: "0.5vw",
                    //                     borderRadius: "5px",
                    //                     cursor: "pointer",
                    //                 }}
                    //                 onClick={downloadPdf}
                    //             >
                    //                 Download
                    //             </button>
                    //         ),
                    //     });
                    // });
                    documentViewer.addEventListener("documentLoaded", () => {
                        setTimeout(() => {
                            if (pageNumber && !isNaN(pageNumber)) {
                                documentViewer.setCurrentPage(pageNumber, true)
                            }
                        }, 1000)
                    });
                })
                .catch((err) => {
                    console.log("Caught Error:", err);
                });
        }
        if (isExternalCheck && !openExternal) {
            asyncWrapper();
        }
    }, [
        documentURL,
        docUrl,
        disableElements,
        disableDownload,
        disableAnnotations,
        gqlUri, pageNumber,
        isExternalCheck,
        openExternal
    ]);
    if (noTokenError && waitForMessageWithToken) {
        return (
            <ErrorMessageComponent message="Error fetching token" />
        )
    }
    if (errorWithParams) {
        return (
            <ErrorMessageComponent message="Something went wrong" paramErrorMessage={paramErrorMessage} />
        )
    }
    if (!isExternalCheck) {
        return (
            <Spinner message="Checking file source" />
        )
    }
    if (openExternal) {
        return (
            <Spinner message="Opening file" />
        )
    }
    return <ViewerDiv ref={viewerDiv}></ViewerDiv>;
};
