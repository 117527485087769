export const IS_PRODUCTION = () => {
    if (process.env?.TARGET_IS_PRODUCTION) return true;
    return !/staging|localhost/i.test(window.location.href);
  };
  

export const VIEWER_GQL_ENDPOINT = IS_PRODUCTION()
  ? 'https://hippocrates.production.botmd.io/gql/'
  : 'https://hippocrates.staging.botmd.io/gql/';


const Config = {
  env: process.env.NODE_ENV,
  IS_PRODUCTION,
  cookie: { name: 'session', maxAge: 2595599 }, // 30 days,
  VIEWER_GQL_ENDPOINT
};

export default Config
  
  