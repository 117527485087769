import { GraphQLClient } from 'graphql-request';
import {VIEWER_GQL_ENDPOINT} from '../../../Config';
import {GQLStitchQuery} from '../../../api/queries/getStitchRedirectLink'

function getPath(url: string) {
    const { pathname } = new URL(url);

    // remove leading /
    return pathname.replace(/^\/+/, '');
}

function extractSessionFromUrl(url: string): string | null {
    const params = new URL(url).searchParams;
    return params.get('x-session');
}

const getRedirectedUrl = async (url: string, baseUrl: string | null): Promise<string> => {
    const pathName = getPath(url as string);
    const GQL_ENDPOINT = baseUrl || VIEWER_GQL_ENDPOINT;
    const session = extractSessionFromUrl(url);

    if (!session) {
        throw new Error("Session not found in URL");
    }

    const client = new GraphQLClient(GQL_ENDPOINT, {
        headers: {
            'x-session': session // Assuming this is the correct header name; adjust as needed
        }
    });
    try {
        const { stitchLink } = await client.request(GQLStitchQuery, { pathName });
        if (!stitchLink) {
            return 'Invalid Session or URL'
        }
        return stitchLink.redirectUrl;

    } catch (error) {
        return 'Invalid Session or URL'
    }
};

function isStitchLink (url : string) {
    const stitchUrlFormats = ["stitch.production.botmd.sg","stitch.botmd.sg", "stitch.staging.botmd.sg", "stitch-v2.staging.botmd.io", "stitch-v3.staging.botmd.io", "s.botmd.io", "t.botmd.io","s.botmd.sg", "t.botmd.sg" ]
    for (let index = 0; index < stitchUrlFormats.length; index ++) {
        if (url.includes(stitchUrlFormats[index])) {
            return true;
        }
    }
    return false;
}


export {isStitchLink, getRedirectedUrl}